
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import Pagination from "@/comp/Pagination/index.vue";
import { getBloggerGameWheelList, exportBloggerGameWheelList } from "@/api/request/blogger";
import { saveAs } from "@/api/request/base";

//组件
@Component({
  name: "bloggerGameWheel",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = []; //列表
  private total: number = 0; //总数
  private listLoading: boolean = false; // 列表加载

  //请求数据
  private listQuery: {
    //页码
    page: any;
    page_size: any;

    //临时数据
    blogger_id_str: string;

    //数据
    blogger_id: number; //博主ID
    game_switch: number; //开关
    blogger_level: number; //博主等级
    blogger_nick_name: string; //博主昵称

    //是否导出
    excel: boolean;
  } = {
    //页码
    page: 1,
    page_size: 20,

    //临时数据
    blogger_id_str: "",

    //数据
    blogger_id: 0,
    game_switch: 1,
    blogger_level: 0,
    blogger_nick_name: "",

    //是否导出
    excel: false,
  };

  //等级列表
  private levelList: any[] = [
    { id: 0, name: "全部博主" },
    { id: 1, name: "见习博主" },
    { id: 2, name: "新晋博主" },
    { id: 3, name: "优秀博主" },
    { id: 4, name: "金牌博主" },
    { id: 5, name: "铂金博主" },
    { id: 6, name: "钻石博主" },
    { id: 7, name: "皇冠博主" },
    { id: 8, name: "首席博主" },
  ];

  //游戏开关
  private switchList: any[] = [
    { id: 1, name: "开启" },
    { id: 3, name: "关闭" },
  ];

  //创建调用
  created() {
    //获取列表
    this.getList();
  }

  // //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.excel = false;
    this.listQuery.blogger_id = Number(this.listQuery.blogger_id_str);

    //获取数据
    const { data } = await getBloggerGameWheelList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理查询
  private handleSearch(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取列表
    this.getList();
  }

  //处理看导出
  private async handleExport() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.excel = true;
    this.listQuery.blogger_id = Number(this.listQuery.blogger_id_str);

    //获取数据
    const { data } = await exportBloggerGameWheelList(this.listQuery);

    //保存数据
    saveAs(data, "博主指令轮盘数据列表");

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //获取博主等级名称
  private getBloggerLevelName(level: number): string {
    //定义变量
    var level_name: string = "";

    //数据赋值
    for (var i: number = 0; i < this.levelList.length; i++) {
      if (level == this.levelList[i].id) {
        level_name = this.levelList[i].name;
        break;
      }
    }

    //返回数据
    return level_name;
  }

  //获取轮盘内容
  private getWheelsDetail(wheels: any[]): string {
    //定义变量
    var detail: string = "";

    //数据赋值
    for (var i: number = 0; i < wheels.length; i++) {
      //数据赋值
      detail += wheels[i].content + " | ";
    }
    detail = detail.substring(0, detail.length - 3);

    //返回数据
    return detail;
  }
}
